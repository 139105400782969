import { Button, Card, Checkbox, Form, List, Space, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import roleService from '../../../services/admin/role.service';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

const DetailRoleFeatures = ({ role, }) => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        features : [],

        edit_mode : false,
    })

    const role_features = useMemo(() => {
        return role?.features;
    }, [role])

    const grouped_features_by_menus = useMemo(() => {
        const groupedByMenuId = state?.features.reduce((acc, feature) => {
            const { menu_id, menu, } = feature;
          
            // If the menu_id doesn't exist in the accumulator, add it
            if (!acc[menu_id]) {
              acc[menu_id] = {
                menu_id,
                icon : menu?.icon,
                name : menu?.name,
                features: [],
              };
            }

            let new_feature = {...feature};
            delete new_feature.menu;
          
            // Push the feature to the corresponding menu group
            acc[menu_id].features.push(new_feature);
          
            return acc;
        }, {});

        return groupedByMenuId;
    }, [state?.features])

    const saveUpdateRoleFeatures = async () => {
        console.log(form?.getFieldsValue());
    }

    const fetchFeatureList = async () => {
        setState(state => ({...state, loading : true, }));
        const featuresResponse = await roleService?.getFeatureList();
        
        const { features, } = featuresResponse;
        setState(state => ({...state, loading : false, features, }));
    }

    useEffect(() => {
        fetchFeatureList();
    }, [])

    return (
        <>
            <Form
            form={form}
            onFinish={saveUpdateRoleFeatures}
            >
                <div style={{ textAlign : 'end', }}>
                    <Space>
                    {
                        state?.edit_mode ?
                        (
                            <>
                                <Button loading={state?.save_loading} onClick={() => form.submit()} size='small' icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />} />
                                <Button onClick={() => setState(state => ({...state, edit_mode : false, }))} size='small' icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />} />
                            </>
                        )
                        :
                        (
                            <>
                                <Button onClick={() => setState(state => ({...state, edit_mode : true, }))} size='small' icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />} />
                            </>
                        )
                    }
                    </Space>
                </div>
                <div style={{ marginTop : 12, }}>
                    <Card
                    styles={{ body : { padding : 0} }}
                    >
                        {
                            Object.keys(grouped_features_by_menus)?.map((menu_id, menu_index) => {
                                const { features, name, icon, } = grouped_features_by_menus?.[menu_id] ?? {}
                                return (
                                    <div key={menu_id}>
                                        <div style={{ textAlign : 'start', padding : 12, background : 'var(--secondary-button-color)', }}>
                                            <span>{name}</span>
                                        </div>
                                        <List
                                        style={{ padding : 0, }}
                                        dataSource={features}
                                        renderItem={(item, index) => {
                                            const { name, feature_id, code, } = item ?? {};
                                            return (
                                                <List.Item key={index} style={{ paddingInline : 12, width : '100%', }}>
                                                    <div style={{ display : 'flex', flexWrap : 'wrap', alignItems : 'center', width : 'inherit', }}>
                                                        <div>
                                                            <span>{name}</span>
                                                        </div>

                                                        <div style={{ marginLeft : 'auto', }}>
                                                            <Form.Item name={['checked', feature_id]} style={{ width : 'inherit', marginBottom : 0, }}>
                                                                <Checkbox />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </Card>
                </div>
            </Form>
        </>
    );
}

export default DetailRoleFeatures;