import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Space, Switch, Tag, notification, } from 'antd';
import dayjs from 'dayjs';
import React, { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { refresh_promo_code_detail } from '../../../actions/config.action';
import promotionService from '../../../services/config/promotion.service';
import numberHelper from '../../../helpers/functions/number.helper';

const PromoDetailEdit = ({ promotion_code }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        loading : false,
        save_loading : false,
        edit_mode : false,
    });

    const savePromotionCode = async () =>{
        setState(state => ({...state, save_loading : true, }));
        const { name, promo_code, percentage, value, max_cap, is_stack, is_specific_visit_date, limit_number, start_date, end_date, is_open_date, } = form.getFieldsValue();
        const { promotion_code_id } = promotion_code;
        const updatePromoCodeResponse = await promotionService?.updatePromoCode(promotion_code_id, {
            name, 
            promo_code, 
            percentage,
            value : (isNaN(parseFloat(value)) ? null : parseFloat(value)), 
            max_cap : (isNaN(parseFloat(max_cap)) ? null : parseFloat(max_cap)), 
            is_stack, 
            is_specific_visit_date, 
            limit_number, 
            start_date,
            end_date,
            is_open_date,
        });


        //notify
        notification.success({
            message : "Promotion Code Updated",
            description : `Updated General Info`
        })
        dispatch(refresh_promo_code_detail());
        setState(state => ({...state, edit_mode : false, save_loading : false, }));
    }

    const initializeForm = () => {
        const { name, promo_code, percentage, value, max_cap, is_stack, is_specific_visit_date, limit_number, start_date, end_date, is_open_date, } = promotion_code ?? {};
        form.setFieldsValue({
            name, promo_code, percentage, value, max_cap, is_stack, is_specific_visit_date, limit_number, is_open_date,
            start_date : start_date ? dayjs(start_date) : null,
            end_date : end_date ? dayjs(end_date) : null,
        });
    }

    useEffect(() => {
        initializeForm();
    }, [promotion_code])

    return (
        <>
            <div>
                <Form
                form={form}
                id="promo_code_form"
                layout='vertical'
                >
                    <div>
                        <div style={{textAlign:'start'}}>
                            <div style={{display:'flex',}}>
                                <div>
                                    <span style={{fontWeight:500,}}>Promotion Code Detail</span>
                                </div>
                                <div style={{marginLeft:'auto'}}>
                                    {
                                        state?.edit_mode ?
                                        (
                                            <>
                                                <Space>
                                                    <Button
                                                    loading={state?.save_loading}
                                                    onClick={savePromotionCode}
                                                    size='small'
                                                    icon={<CheckOutlined style={{color:'var(--secondary-text-color)'}} />}
                                                    />
                                                    <Button
                                                    onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                                    size='small'
                                                    icon={<CloseOutlined style={{color:'var(--secondary-text-color)'}} />}
                                                    />
                                                </Space>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Space>
                                                    <Button
                                                    onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                                    size='small'
                                                    icon={<EditOutlined style={{color:'var(--secondary-text-color)'}} />}
                                                    />
                                                </Space>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop:12,}}>
                            <div>
                                <div>
                                    <Row gutter={[8, 8]}>
                                        <Col span={24}>
                                            <div>
                                                <Form.Item label="Park" name='park'>
                                                    <div style={{textAlign:'start'}}>
                                                        <span style={{color:'var(--secondary-text-color)'}}>{promotion_code?.parks?.name}</span>
                                                    </div>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div>
                                                <Form.Item label="Name" name='name'>
                                                    {
                                                        state?.edit_mode ?
                                                        (
                                                            <Input placeholder='Promotion Code Name' />
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <div style={{textAlign:'start'}}>
                                                                    <span style={{color:'var(--secondary-text-color)'}}>{promotion_code?.name}</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div>
                                                <Form.Item label="Promo Code" name='promo_code'>
                                                    {
                                                        state?.edit_mode ?
                                                        (
                                                            <Input placeholder='Promotion Code Name' />
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <div style={{textAlign:'start'}}>
                                                                    <Tag color='orange' style={{border:'none',}}>
                                                                        <span>{promotion_code?.promo_code}</span>
                                                                    </Tag>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <Divider style={{margin:0,}} />
                                <div style={{marginTop:12,}}>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{fontWeight:500,}}>Promotion Amount</span>
                                    </div>
                                    <div style={{marginTop:12,}}>
                                        <Row gutter={[8, 8]}>
                                            <Col span={8}>
                                                <Form.Item label="Percentage" name='percentage'>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <Input allowClear type='number' style={{width:'100%'}} suffix={"%"} />
                                                    )
                                                    :
                                                    (

                                                        <>
                                                            <div style={{textAlign:'start'}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{`${promotion_code?.percentage ?? numberHelper?.commaNumber(0)}%`}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item label="Value" name='value'>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <Input allowClear type='number' style={{width:'100%'}} prefix={promotion_code?.parks?.currency?.code} />
                                                    )
                                                    :
                                                    (

                                                        <>
                                                            <div style={{textAlign:'start'}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{`${promotion_code?.parks?.currency?.code}${promotion_code?.value ?? numberHelper.commaNumber(0)}`}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item label="Cap" name='max_cap'>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <Input allowClear type='number' style={{width:'100%'}} prefix={promotion_code?.parks?.currency?.code} />
                                                    )
                                                    :
                                                    (

                                                        <>
                                                            <div style={{textAlign:'start'}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{`${promotion_code?.parks?.currency?.code}${promotion_code?.max_cap ?? numberHelper.commaNumber(0)}`}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <Divider style={{margin:0,}} />
                                <div style={{marginTop:12,}}>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{fontWeight:500,}}>Valid Date</span>
                                    </div>

                                    <div style={{marginTop:12,}}>
                                        <Row gutter={[8, 8]}>
                                            <Col span={12}>
                                                <Form.Item label="Start Date" name='start_date'>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <DatePicker format={"YYYY-MM-DD"}  style={{width:'100%'}} allowClear />
                                                    )
                                                    :
                                                    (

                                                        <>
                                                            <div style={{textAlign:'start'}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{promotion_code?.start_date ?? "Unset"}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="End Date" name='end_date'>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <DatePicker format={"YYYY-MM-DD"}  style={{width:'100%'}} allowClear />
                                                    )
                                                    :
                                                    (

                                                        <>
                                                            <div style={{textAlign:'start'}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{promotion_code?.end_date ?? "Unset"}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <Divider style={{margin:0,}} />
                                <div style={{marginTop:12,}}>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{fontWeight:500,}}>Others</span>
                                    </div>

                                    <div style={{marginTop:12,}}>
                                        <Row gutter={[8, 8]}>
                                            <Col span={12}>
                                                <div style={{textAlign:'start'}}>
                                                    <Form.Item label="Discount Stackability" name='is_stack'>
                                                    {
                                                        state?.edit_mode ?
                                                        (
                                                            <Switch defaultChecked={form?.getFieldValue("is_stack")} />
                                                        )
                                                        :
                                                        (

                                                            <>
                                                                <div>
                                                                    <span style={{color:'var(--secondary-text-color)'}}>{promotion_code?.is_stack ? 'Yes' : 'No'}</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div style={{textAlign:'start'}}>
                                                    <Form.Item label="Open Date" name='is_open_date'>
                                                    {
                                                        state?.edit_mode ?
                                                        (
                                                            <Switch defaultChecked={form?.getFieldValue("is_open_date")} />
                                                        )
                                                        :
                                                        (

                                                            <>
                                                                <div>
                                                                    <span style={{color:'var(--secondary-text-color)'}}>{promotion_code?.is_open_date ? 'Yes' : 'No'}</span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item label="Limit" name='limit_number'>
                                                {
                                                    state?.edit_mode ?
                                                    (
                                                        <InputNumber style={{width:'100%'}} allowClear />
                                                    )
                                                    :
                                                    (

                                                        <>
                                                            <div style={{textAlign:'start'}}>
                                                                <span style={{color:'var(--secondary-text-color)'}}>{promotion_code?.limit_number ?? "Unset"}</span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label="Visit Date Availability Type" name='is_specific_visit_date'>
                                                 {
                                                        state?.edit_mode ?
                                                        (
                                                            <Select 
                                                            options={[{
                                                                label : "All Date",
                                                                value : false,
                                                            },{
                                                                label : "Specific Date",
                                                                value : true,
                                                            }]}
                                                            />
                                                        )
                                                        :
                                                        (

                                                            <>
                                                                <div style={{textAlign:'start',}}>
                                                                    <span style={{color:'var(--secondary-text-color)'}}>
                                                                        {
                                                                        promotion_code?.is_specific_visit_date ? 
                                                                        'Specific Date' : 'All Date'
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default PromoDetailEdit;